<template>
  <div>
    <header class="head">
      <div class="head-box">
        <img :src="data.logo" />
        <div class="title-box">
          <p class="title-en">SERVICE PLATFORM</p>
          <p class="title-cn">{{data.cityName}}不动产公共服务平台</p>
        </div>
      </div>
    </header>
    <section class="section">
      <div class="item-box">
        <div class="item" v-for="(item,index) in data.itemArr" :key="'item' + index" @click="tiaozhuan(item)">
          <div class="item-icon" :style="'background:url(' + item.bg + ') no-repeat center;background-size:cover;'">
            <div class="icon">
              <img :width="item.width" :height="item.height" :src="item.icon" />
            </div>
          </div>
          <div class="item-text">
            <span>{{item.text}}</span>
          </div>
        </div>
        <div class="item" v-if="isShow"></div>
      </div>
    </section>
    <footer class="foot">
      <div class="code-box" v-if="type=='computer'">
        <img width="80" height="80" :src="data.codeGzh" />
        <p>扫码关注公众号</p>
      </div>
      <div class="font-box">
        <div class="f-flex">
          <p>主管：{{data.cityName}}自然资源和规划局</p>
          <p>主办：{{data.cityName}}不动产登记中心</p>
        </div>
        <div class="f-flex">
          <p>备案号：<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">{{data.beian}}</a></p>
          <p>联系地址：{{data.contactAddress}}</p>
        </div>
        <div class="f-flex">
          <p>技术支持：西安必特思维软件有限公司</p>
        </div>
      </div>
      <div class="code-box" v-if="type=='computer'">
        <img width="80" height="80" :src="data.codeApp" />
        <p>扫码下载APP</p>
      </div>
      <div class="flex" v-if="type=='phone'">
        <div style="text-align:center;">
          <img width="80" height="80" :src="data.codeGzh" />
          <div style="margin-top:0;">扫码关注公众号</div>
        </div>
        <div style="text-align:center;">
          <img width="80" height="80" :src="data.codeApp" />
          <div style="margin-top:0;">扫码下载APP</div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { BaoJi } from './common.js'
export default {
  data() {
    return {
      data: '',
      type: '',
      isShow: false
    }
  },
  created() {
    this.data = BaoJi
    var windowWidth = document.documentElement.clientWidth || document.body.clientWidth
    if (windowWidth <= 750) {
      this.type = 'phone'
    } else {
      this.type = 'computer'
    }
  },
  mounted() {
    // const el = document.getElementsByClassName('item-icon')
    // const str0 = el[0].getAttribute('style')
    // const str1 = el[1].getAttribute('style')
    // const str2 = el[2].getAttribute('style')
    // const str3 = el[3].getAttribute('style')
    // const str4 = el[4].getAttribute('style')
    // const str5 = el[5].getAttribute('style')
    // const str6 = el[6].getAttribute('style')
    // const str7 = el[7].getAttribute('style')
    // el[0].setAttribute('style', str0 + 'height:' + (el[0].offsetWidth * 0.7 - 40) + 'px')
    // el[1].setAttribute('style', str1 + 'height:' + (el[0].offsetWidth * 0.7 - 40) + 'px')
    // el[2].setAttribute('style', str2 + 'height:' + (el[0].offsetWidth * 0.7 - 40) + 'px')
    // el[3].setAttribute('style', str3 + 'height:' + (el[0].offsetWidth * 0.7 - 40) + 'px')
    // el[4].setAttribute('style', str4 + 'height:' + (el[0].offsetWidth * 0.7 - 40) + 'px')
    // el[5].setAttribute('style', str5 + 'height:' + (el[0].offsetWidth * 0.7 - 40) + 'px')
    // el[6].setAttribute('style', str6 + 'height:' + (el[0].offsetWidth * 0.7 - 40) + 'px')
    // el[7].setAttribute('style', str7 + 'height:' + (el[0].offsetWidth * 0.7 - 40) + 'px')
  },
  methods: {
    changeType(type) {
      this.selectType = type
    },
    tiaozhuan(item) {
      const link = document.createElement('a')
      link.href = item.url
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
}
@media screen and (min-width: 750px) {
  .head {
    width: 100%;
    height: 200px;
    background: url("./images/head-bg.png") no-repeat center;
    user-select: none;
    .head-box {
      width: 70%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: nowrap;
      margin: auto;
      padding-top: 30px;
      img {
        width: 100px;
        height: 100px;
      }
      .title-box {
        height: 100px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-left: 20px;
        padding-bottom: 5px;
        .title-en {
          font-size: 30px;
          font-weight: 400;
          color: #ffffff;
          letter-spacing: 3px;
          margin-top: 0;
        }
        .title-cn {
          font-size: 35px;
          font-weight: bold;
          color: #ffffff;
          letter-spacing: 5px;
          margin-top: 0;
        }
      }
    }
  }

  .section {
    width: 100%;
    min-height: calc(100vh - 350px);
    background: url("./images/main-bg.png") no-repeat center;
    padding: 60px 0 0 0;
    .item-box {
      width: 100%;
      box-sizing: border-box;
      padding: 0 15%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item {
        width: 24%;
        height: 190px;
        margin-bottom: 36px;
        cursor: pointer;
        .item-icon {
          height: 150px;
          position: relative;
          .icon {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            height: 60px;
            width: 60px;
            background-color: #fff;
            border-radius: 4px 4px 4px 4px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .item-text {
          height: 40px;
          background-color: #fff;
          color: #333333;
          font-weight: bold;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .item:hover {
        animation: jello 1.5s linear infinite;
      }
      @keyframes jello {
        0%,
        11.1%,
        100% {
          -webkit-transform: translateZ(0);
          transform: translateZ(0);
        }
        22.2% {
          -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
          transform: skewX(-12.5deg) skewY(-12.5deg);
        }
        33.3% {
          -webkit-transform: skewX(6.25deg) skewY(6.25deg);
          transform: skewX(6.25deg) skewY(6.25deg);
        }
        44.4% {
          -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
          transform: skewX(-3.125deg) skewY(-3.125deg);
        }
        55.5% {
          -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
          transform: skewX(1.5625deg) skewY(1.5625deg);
        }
        66.6% {
          -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
          transform: skewX(-0.78125deg) skewY(-0.78125deg);
        }
        77.7% {
          -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
          transform: skewX(0.390625deg) skewY(0.390625deg);
        }
        88.8% {
          -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
          transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
        }
      }
    }
  }

  .foot {
    width: 100%;
    height: 150px;
    box-sizing: border-box;
    background: #d8d6d6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15%;
    .font-box {
      flex: 1;
      height: 110px;
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .code-box {
      height: 110px;
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
    .f-flex {
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        text-align: center;
        color: #333333;
        font-weight: 400;
        font-size: 16px;
        margin-right: 20px;
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .head {
    width: 100%;
    height: 150px;
    background: url("./images/head-bg.png") no-repeat center;
    user-select: none;
    .head-box {
      width: 90%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: nowrap;
      margin: auto;
      padding-top: 30px;
      img {
        width: 75px;
        height: 75px;
      }
      .title-box {
        height: 75px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-left: 20px;
        padding-bottom: 5px;
        .title-en {
          font-size: 18px;
          line-height: 18px;
          font-weight: 400;
          color: #ffffff;
          margin-top: 0;
          letter-spacing: 3px;
        }
        .title-cn {
          font-size: 24px;
          font-weight: bold;
          line-height: 28px;
          color: #ffffff;
          letter-spacing: 5px;
          margin-top: 0;
        }
      }
    }
  }

  .section {
    width: 100%;
    min-height: calc(100vh - 300px);
    background: url("./images/main-bg.png") no-repeat center;
    padding: 40px 0 0 0;
    .item-box {
      width: 90%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: auto;
      .item {
        width: 24%;
        height: 190px;
        margin-bottom: 24px;
        cursor: pointer;
        .item-icon {
          height: 130px;
          position: relative;
          .icon {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            height: 50px;
            width: 50px;
            background-color: #fff;
            border-radius: 4px 4px 4px 4px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .item-text {
          height: 40px;
          background-color: #fff;
          color: #333333;
          font-weight: bold;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .item:hover {
        animation: jello 1.5s linear infinite;
      }
      @keyframes jello {
        0%,
        11.1%,
        100% {
          -webkit-transform: translateZ(0);
          transform: translateZ(0);
        }
        22.2% {
          -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
          transform: skewX(-12.5deg) skewY(-12.5deg);
        }
        33.3% {
          -webkit-transform: skewX(6.25deg) skewY(6.25deg);
          transform: skewX(6.25deg) skewY(6.25deg);
        }
        44.4% {
          -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
          transform: skewX(-3.125deg) skewY(-3.125deg);
        }
        55.5% {
          -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
          transform: skewX(1.5625deg) skewY(1.5625deg);
        }
        66.6% {
          -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
          transform: skewX(-0.78125deg) skewY(-0.78125deg);
        }
        77.7% {
          -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
          transform: skewX(0.390625deg) skewY(0.390625deg);
        }
        88.8% {
          -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
          transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
        }
      }
    }
  }

  .foot {
    width: 100%;
    box-sizing: border-box;
    background: #d8d6d6;
    padding-top: 18px;
    padding-bottom: 18px;
    p {
      text-align: center;
      margin-bottom: 5px;
      color: #333333;
      font-weight: 400;
      font-size: 14px;
    }
  }
}
</style>
