/*门户网站页面配置*/
export const BaoJi = {
    logo: require('./images/LOGO.png'),
    cityName: "宝鸡市",
    itemArr: [
        {
            bg: require('./images/m1.png'),
            text: '互联网+一体化登陆',
            icon: require('./images/i1.png'),
            width: 36,
            height: 36,
            url: 'http://yth.bjsbdc.com.cn/ii/#/login'
        },
        {
            bg: require('./images/m2.png'),
            text: '商品房网上备案系统登陆',
            icon: require('./images/i2.png'),
            width: 36,
            height: 36,
            url: 'http://61.185.68.162/ytt/'
        },
        {
            bg: require('./images/m3.png'),
            text: '公示公告',
            icon: require('./images/i3.png'),
            width: 28,
            height: 36,
            url: 'http://61.185.68.162/info1.asp?channel=%B9%AB%CA%BE%B9%AB%B8%E6'
        },
        {
            bg: require('./images/m4.png'),
            text: '表格下载',
            icon: require('./images/i4.png'),
            width: 33,
            height: 34,
            url: 'http://61.185.68.162/info1.asp?channel=%B1%ED%B8%F1%CF%C2%D4%D8'
        },
        {
            bg: require('./images/m5.png'),
            text: '业务进度查询',
            icon: require('./images/i5.png'),
            width: 27,
            height: 30,
            url: 'http://61.185.68.162/bit-xxzs/xxzs/chax/bzjgcx.asp'
        },
        {
            bg: require('./images/m6.png'),
            text: '预售合同查询',
            icon: require('./images/i6.png'),
            width: 24,
            height: 30,
            url: 'http://61.185.68.162/bit-xxzs/xxzs/chax/qfqychaxun.asp'
        },
        {
            bg: require('./images/m7.png'),
            text: '现售合同查询',
            icon: require('./images/i7.png'),
            width: 24,
            height: 30,
            url: 'http://61.185.68.162/bit-xxzs/xxzs/chax/xfqychaxun.asp'
        },
        {
            bg: require('./images/m8.png'),
            text: '预售项目查询',
            icon: require('./images/i8.png'),
            width: 29,
            height: 28,
            url: 'http://61.185.68.162/bit-xxzs/xmlpzs/webissue.asp'
        },
    ],
    app: require('./images/app.png'),
    appSelect: require('./images/app-select.png'),
    gzh: require('./images/gzh.png'),
    gzhSelect: require('./images/gzh-select.png'),
    codeGzh: require('./images/code-gzh.png'),
    codeApp: require('./images/code-app.png'),
    beian: "陕ICP备17010090号-1",
    contactAddress: "陕西省宝鸡市金台区陈仓园市民中心"
}
